import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import './Header.scss';
import { urlFor, client } from '../../client'

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    const query = '*[_type == "abouts"]'

    client.fetch(query).then((data) => {
      setAbouts(data)
    })
  }, [])

  return (
    <>
      <div className="app__header app__flex">
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__header-info"
        >
          <div className="app__header-badge">
            <div className="badge-cmp app__flex">
              <div>
                <h4 className="head-text">İsmail Sergen Göçmen</h4>
              </div>
            </div>

            <div className="tag-cmp app__flex">
              <p className="p-text">Software Engineer</p>
            </div>
          </div>
        </motion.div>
        <div className='app__profiles'>
          {abouts.map((about, index) => (
            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className='app__profile-item'
              key={about.title + index}
            >
              <img
                width='190'
                height='190'
                style={about.title === 'CV' ? {cursor: 'pointer'} : {}}
                src={urlFor(about.imgUrl)}
                alt={about.description}
                onClick={() => {
                  if (about.title.localeCompare('CV') == 0) {
                    window.open(
                      'https://cdn.sanity.io/files/0yk49vo3/production/e8edae0b0895e29ac820b6d9325815e4478bd82c.pdf'
                    )
                  }
                }}
              />
              <h6 className='bold-text' style={{ marginTop: 20 }}>
                {about.description}
              </h6>
              {/* <p className='p-text' style={{ marginTop: 10 }}>
                {about.description}
              </p> */}
            </motion.div>
          ))}
        </div>
      </div>
      
  </>
  )
};

export default AppWrap(Header, 'home');
