import bilsen_icon from "../assets/company/bilsen.png";
import iotiq_icon from "../assets/company/iotiq.png";
import erste_icon from "../assets/company/erste.png";

const experiences = [
  {
    title: "Researcher",
    company_name: "BILSEN",
    icon: bilsen_icon,
    iconBg: "#383E56",
    date: "July 2023 - Ongoing",
    points: [
      "Conducted research on enhancing code review and process smells",
      "Organized and facilitated focus group sessions to gather insight from developers and managers",
      "Currently preparing 2 research papers for publication",
      "Contributing to the academic discourse on code review processes"
    ],
  },
  {
    title: "Part-time Software Engineer",
    company_name: "BILSEN",
    icon: bilsen_icon,
    iconBg: "#383E56",
    date: "September 2022 - July 2023",
    points: [
      "Continued to work on the Smellyzer project with the team of Asst. Prof. Eray Tüzün",
      "Implemented and improved detection logic of 7 smells for Azure DevOps and Jira",
      "Used Java, Spring Boot, Python, Vue, GraphQL, Neo4j, RabbitMQ and Docker",
      "Created advertisement and setup videos as well as business model canvas for commercialization",
      "Added functionality to support configuration of Jira Bot",
      "Conducted product presentation to Arçelik and JetBrains"
    ],
  },
  {
    title: "Research and Development Intern",
    company_name: "IOTIQ GmbH",
    icon: iotiq_icon,
    iconBg: "#E6DEDD",
    date: "July 2022 - August 2022",
    points: [
      "Worked on the SmartDelta ITEA4 project which aims to create solutions for quality assessment of software projects",
      "Conducted a literature review with 30+ article, created a document and presentation about change impact analysis",
      "Presented the document to a consortium which consist of 35 companies from 10 different countries",
    ],
  },
  {
    title: "Full Stack Developer Intern",
    company_name: "Erste Software",
    icon: erste_icon,
    iconBg: "#FFFFFF",
    date: "June 2022 - July 2022",
    points: [
      "Worked on the Smellyzer project, which is funded in the scope of TÜBİTAK 1505 program",
      "Used Java, Spring Boot, Vue, Python, GraphQL, Neo4j and Docker",
      "Developed and implemented responsible people algorithm according to the requirements",
    ],
  },
];

export default experiences;
