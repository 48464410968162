import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { AppWrap, MotionWrap } from '../../wrapper'
import { motion } from "framer-motion";
import "./Experience.scss";

import experiences from "../../constants/experiences";
import { textVariant } from "../../wrapper/motion";

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#1d1836",
        color: "#edf2f8",
      }}
      contentArrowStyle={{ borderRight: "7px solid #1d1836" }}
      date={experience.date}
      dateClassName={"text-date"}
      iconStyle={{ background: experience.iconBg }}
      icon={
        <div className='timeline-icon_holder'>
          <img
            src={experience.icon}
            alt={experience.company_name}
            className='timeline-icon'
          />
        </div>
      }
    >
      <div>
        <h3>{experience.title}</h3>
        <p
          className="text-company"
          style={{ margin: 0 }}
        >
          {experience.company_name}
        </p>
      </div>

      <ul className='mt-5 list-disc ml-5 space-y-2'>
        {experience.points.map((point, index) => (
          <li key={`experience-point-${index}`} 
            className='text-white-100 text-[14px] pl-1 tracking-wider space-y-2'>
            {point}
          </li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <div className="mainDiv">
      <motion.div variants={textVariant()}>
        <h2 className="head-text">
          Work Experience
        </h2>
      </motion.div>

      <div className='mainDiv'>
        <VerticalTimeline
          lineColor={"#edf2f8"}>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Experience, 'app__experience'),
  'experience',
  'app__whitebg'
)