import React from 'react'
import { BsLinkedin, BsGithub, BsSpotify } from 'react-icons/bs'
import './SocialMedia.scss'

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <div>
          <BsLinkedin
            onClick={() =>
              window.open('https://www.linkedin.com/in/ismailsergengocmen')
            }
          ></BsLinkedin>
      </div>
      <div>
        <BsGithub
          onClick={() => window.open('https://github.com/ismailsergengocmen')}
        ></BsGithub>
      </div>
      <div>
        <BsSpotify
          onClick={() =>
            window.open(
              'https://open.spotify.com/user/11141020661?si=6f98626c31034731'
            )
          }
        ></BsSpotify>
      </div>
    </div>
  )
}

export default SocialMedia
