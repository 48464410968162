import React from 'react'

import { Header, Skills, Projects, Experience, Certificates } from './container'
import { Navbar } from './components';
import './App.scss';

const App = () => {
  return (
    <>
      <div className='app'>
        <Navbar />
        <Header />
        <Experience />
        <Projects />
        <Skills />
        <Certificates />
      </div>
    </>
  )
}

export default App
